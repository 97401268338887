.skeleton {
  display: inline-block;
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: #210330
}

.skeleton::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(155, 101, 255, 0.2) 30%,
    rgba(138, 21, 255, 0.3) 40%,
    rgba(255, 255, 255, 0) 60%
  );
  animation: shimmer 2s infinite;
  content: '';
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
